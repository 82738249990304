<template>
  <div id="Brief">
    <Header />

    <div class="B1">
      <div class="B1F2">
        <div class="B1F2_B1">
          <p class="B1T1">Brief</p>
          <p class="B1Text">
            En Legrafica nos mueve la creatividad, los retos, y evolucionar
            junto con el mundo digital. No nos quedamos quietos y siempre
            exploramos nuevas formas en las que tu marca puede resaltar,
            manteniendo presentes nuestros valores como agencia.
          </p>
        </div>
        <div class="B1F2_B2">
          <img src="../assets/Frame1.png" />
        </div>
      </div>
    </div>
    <div class="forms-cont">
      <form
        @submit.prevent="
          enviarBrief(
      nombreE,
      antiguedadE,
      proSerE,
      ubicacionE,
      telefonoE,
      segmentacionE,
      misionE,
      visionE,
      valoresE,
      manualE,
      estudioE,
      directaE,
      indirectaE,
      archivo1,
      nombreArch1,
      archivo2,
      nombreArch2,
      linkE,
      inversionE,
      referenciaUnoE,
      referenciaDosE,
      referenciaTresE,
      nombrePE,
      telefonoPE,
      correoPE
          )
        "
      >
        <div class="column-form">
          <div class="bloque-1">
            <div class="formulario">
              <p class="titulo-form">Empresa</p>
              <div class="three-inputs">
                <div class="inputs">
                  <label for="nombreE">Nombre</label>
                  <input
                    type="text"
                    name="nombreE"
                    v-model="nombreE"
                    id="nombreE"
                  />
                </div>
                <div class="inputs">
                  <label for="antiguedadE">Antiguedad</label>
                  <input
                    type="text"
                    name="antiguedadE"
                    v-model="antiguedadE"
                    id="antiguedadE"
                  />
                </div>
                <div class="inputs">
                  <label for="proSerE">Producto/Servicio</label>
                  <input
                    type="text"
                    name="proSerE"
                    v-model="proSerE"
                    id="proSerE"
                  />
                </div>
              </div>
              <div class="two-input">
                <div class="inputs">
                  <label for="ubicacionE">Ubicación</label>
                  <input
                    type="text"
                    name="ubicacionE"
                    v-model="ubicacionE"
                    id="ubicacionE"
                  />
                </div>
                <div class="inputs">
                  <label for="telefonoE">Teléfono de contacto</label>
                  <input
                    type="text"
                    name="telefonoE"
                    v-model="telefonoE"
                    id="telefonoE"
                  />
                </div>
              </div>
              <div class="one-input">
                <div class="inputs">
                  <label for="segmentacionE">Segmentación del mercado</label>
                  <input
                    type="text"
                    name="segmentacionE"
                    v-model="segmentacionE"
                    id="segmentacionE"
                  />
                </div>
              </div>
              <div class="three-inputs">
                <div class="inputs">
                  <label for="misionE">Misión</label>
                  <input
                    type="text"
                    name="misionE"
                    v-model="misionE"
                    id="misionE"
                  />
                </div>
                <div class="inputs">
                  <label for="visionE">Visión</label>
                  <input
                    type="text"
                    name="visionE"
                    v-model="visionE"
                    id="visionE"
                  />
                </div>
                <div class="inputs">
                  <label for="valoresE">Valores</label>
                  <input
                    type="text"
                    name="valoresE"
                    v-model="valoresE"
                    id="valoresE"
                  />
                </div>
              </div>
              <div class="two-input check-cont">
                <div class="inputs">
                  <p class="check-t">¿Cuentas con manual de identidad?</p>
                  <div class="two-checks">
                    <div class="check-c">
                    <input
                      type="radio"
                      name="manualE"
                      v-model="manualE"
                      id="manualE"
                      class="radio"
                      value="Si"
                    />
                    <label for="manualE"></label>
                    <p class="check-t word">Si</p>
                    </div>
                    <label class="uploadManual"
                    v-if="manualE == 'Si'" for="uploadManual">
                      <p>Adjunto</p>
                    </label>
                    <input
                      type="file"
                      id="uploadManual"
                      name="uploadManual"
                      @change="manualFile"
                      class="uploadManual"
                    />
                    <div class="check-c">
                      <input
                        type="radio"
                        name="manualNoE"
                        v-model="manualE"
                        id="manualNoE"
                        class="radio"
                        value="No"
                      />
                      <label for="manualNoE"></label>
                      <p class="check-t word">No</p>
                    </div>
                  </div>
                  <p class="filename">{{this.nombreArch1}}</p>
                </div>
                <div class="inputs">
                  <p class="check-t">¿Cuentas con estudio de mercado?</p>
                  <div class="two-checks">
                    
                    <div class="check-c">
                      <input
                        type="radio"
                        name="estudioE"
                        v-model="estudioE"
                        id="estudioE"
                        class="radio"
                        value="Si"
                      />
                      <label for="estudioE"></label>
                      <p class="check-t word">Si</p>
                    </div>
                    <label class="uploadManual"
                    v-if="estudioE == 'Si'"  for="uploadEstudio">
                      <p>Adjunto</p>
                    </label>
                    <input
                      type="file"
                      id="uploadEstudio"
                      @change="estudioFile"
                      name="uploadEstudio"
                      class="uploadManual"
                    />
                    <div class="check-c">
                      <input
                        type="radio"
                        name="estudioPE"
                        v-model="estudioE"
                        id="estudioPE"
                        class="radio"
                        
                        value="No"
                      />
                      <label for="estudioPE"></label>
                      <p class="check-t word">No</p>
                    </div>
                  </div>
                  <p class="filename">{{this.nombreArch2}}</p>
                </div>
              </div>
              <div class="two-input">
                <div class="inputs">
                  <label for="directaE">Competencia directa</label>
                  <input
                    type="text"
                    name="directaE"
                    v-model="directaE"
                    id="directaE"
                  />
                </div>
                <div class="inputs">
                  <label for="indirectaE">Competencia indirecta</label>
                  <input
                    type="text"
                    name="indirectaE"
                    v-model="indirectaE"
                    id="indirectaE"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="bloque-2">
            <div class="formulario">
              <p class="titulo-form">Redes Sociales</p>
              <div class="two-input">
                <div class="inputs">
                  <label for="linkE">Link</label>
                  <input type="text" name="linkE" v-model="linkE" id="linkE" />
                </div>
                <div class="inputs">
                  <label for="inversionE"
                    >¿Realizas inversión en Ads? Cantidad</label
                  >
                  <input
                    type="text"
                    name="inversionE"
                    v-model="inversionE"
                    id="inversionE"
                  />
                </div>
              </div>
              <div class="one-input">
                <div class="inputs">
                  <label for="referenciaUnoE">Referencias de diseño</label>
                  
                  <input
                    type="text"
                    id="referenciaUnoE"
                    v-model="referenciaUnoE"
                    name="referenciaUnoE"
                    placeholder="Referencia 1"
                  />
                  <label for="referenciaDosE"></label>
                  <input
                    type="text"
                    id="referenciaDosE"
                    v-model="referenciaDosE"
                    name="referenciaDosE"
                    placeholder="Referencia 2"
                  />
                  <label for="referenciaTresE"></label>
                  <input
                    type="text"
                    id="referenciaTresE"
                    v-model="referenciaTresE"
                    name="referenciaTresE"
                    placeholder="Referencia 3"
                  />
                </div>
              </div>
              <div class="submit-cont pc">
                <input
                  type="submit"
                  value="Enviar información"
                  class="submitInfo"
                />
                <div v-if="this.status == 'success'">
              <p class="cart_msg cart_success">{{ this.message }}</p>
            </div>
            <div v-if="this.status == 'error'">
              <p class="cart_msg cart_error">{{ this.message }}</p>
            </div>
                <p class="confidencial">
                  Toda la información proporcionada es confidencial. Consulta
                  nuestro aviso de privacidad.
                </p>
            
              </div>
            </div>
          </div>
        </div>

        <div class="column-form">
          <div class="bloque-3">
            <div class="formulario">
              <p class="titulo-form">Persona encargada</p>
              <div class="one-input">
                <div class="inputs">
                  <label for="nombrePE">Nombre</label>
                  <input
                    type="text"
                    name="nombrePE"
                    v-model="nombrePE"
                    id="nombrePE"
                  />
                </div>
              </div>
              <div class="one-input">
                <div class="inputs">
                  <label for="telefonoPE">Teléfono</label>
                  <input
                    type="text"
                    name="telefonoPE"
                    v-model="telefonoPE"
                    id="telefonoPE"
                  />
                </div>
              </div>
              <div class="one-input">
                <div class="inputs">
                  <label for="correoPE">Correo</label>
                  <input
                    type="text"
                    name="correoPE"
                    v-model="correoPE"
                    id="correoPE"
                  />
                </div>
              </div>
              <div class="submit-cont mo">
                <input
                  type="submit"
                  value="Enviar información"
                  class="submitInfo"
                />

                <div v-if="this.status == 'success'">
              <p class="cart_msg cart_success">{{ this.message }}</p>
            </div>
            <div v-if="this.status == 'error'">
              <p class="cart_msg cart_error">{{ this.message }}</p>
            </div>

                <p class="confidencial">
                  Toda la información proporcionada es confidencial. Consulta
                  nuestro aviso de privacidad.
                </p>
          
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/on-site/Header.vue";
import Footer from "../components/on-site/Footer.vue";

export default {
  name: "Brief",
  data() {
    return {
      disAdd:true,

/**LISTAS BRIEF */
      nombreE: "",
      antiguedadE: "",
      proSerE: "",
      ubicacionE: "",
      telefonoE: "",
      segmentacionE: "",
      misionE: "",
      visionE: "",
      valoresE: "",
      manualE: "",
      archivo1:[],
      nombreArch1:"",
      estudioE: "",
      archivo2:[],
      nombreArch2:"",
      directaE: "",
      indirectaE: "",
      linkE: "",
      inversionE: "",
      referenciaUnoE: "",
      referenciaDosE: "",
      referenciaTresE: "",
      nombrePE: "",
      telefonoPE: "",
      correoPE: "",
      status: "",
      message: "",
    };
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    delStatus: function () {
      setTimeout(() => this.delMsgs(), 5000);
      /*this.close(), 3000);*/
    },
    delMsgs: function () {
      this.status = "";
      this.message = "";
    },
    noAnswer(){
      this.archivo1=[];
      this.nombreArch1="";
    },
    noAnswer2(){
      this.archivo2=[];
      this.nombreArch2="";
    },
    manualFile(event) {
      if(this.manualE == 'No'){
        this.archivo1 = 'No'
      }else if(this.manualE == 'Si'){
        var files = event.target.files || event.dataTransfer.files;
        if (!files.length) return;
        console.log(files[0])
        this.archivo1 = files[0];
        this.nombreArch1 = this.archivo1.name;
      }
    },
    estudioFile(event) {
      if(this.estudioE == 'No'){
        this.archivo2 = 'No'
      }else if(this.estudioE == 'Si'){
        var files = event.target.files || event.dataTransfer.files;
        if (!files.length) return;
        console.log(files[0])
        this.archivo2 = files[0];
        this.nombreArch2 = this.archivo2.name;
      }
    },
    enviarBrief: async function (
      nombreE,
      antiguedadE,
      proSerE,
      ubicacionE,
      telefonoE,
      segmentacionE,
      misionE,
      visionE,
      valoresE,
      manualE,
      estudioE,
      directaE,
      indirectaE,
      archivo1,
      nombreArch1,
      archivo2,
      nombreArch2,
      linkE,
      inversionE,
      referenciaUnoE,
      referenciaDosE,
      referenciaTresE,
      nombrePE,
      telefonoPE,
      correoPE
    ) {
      this.message = "";
      this.status = "";
      if (nombreE == undefined || nombreE == "" || nombreE == null) {
        this.message = "El campo 'nombre' en la sección Empresa no es valido.";
        this.status = "error";
      } else if (
        antiguedadE == undefined ||
        antiguedadE == "" ||
        antiguedadE == null
      ) {
        this.message = "El campo 'antiguedad' en la sección Empresa no es valido.";
        this.status = "error";
        label.add('redone')
      } else if (proSerE == undefined || proSerE == "" || proSerE == null) {
        this.message = "El campo 'producto/servicio' en la sección Empresa no es valido.";
        this.status = "error";
      } else if (
        ubicacionE == undefined ||
        ubicacionE == "" ||
        ubicacionE == null
      ) {
        this.message = "El campo 'ubicación' en la sección Empresa no es valido.";
        this.status = "error";
      } else if (
        telefonoE == undefined ||
        telefonoE == "" ||
        telefonoE == null
      ) {
        this.message = "El campo 'teléfono' en la sección Empresa no es valido.";
        this.status = "error";
      } else if (
        segmentacionE == undefined ||
        segmentacionE == "" ||
        segmentacionE == null
      ) {
        this.message = "El campo 'segmentación de mercado' en la sección Empresa no es valido.";
        this.status = "error";
      } else if (misionE == undefined || misionE == "" || misionE == null) {
        this.message = "El campo 'misión' en la sección Empresa no es valido.";
        this.status = "error";
      } else if (visionE == undefined || visionE == "" || visionE == null) {
        this.message = "El campo 'visión' en la sección Empresa no es valido.";
        this.status = "error";
      } else if (valoresE == undefined || valoresE == "" || valoresE == null) {
        this.message = "El campo 'valores' en la sección Empresa no es valido.";
        this.status = "error";
      } else if (manualE == undefined || manualE == "" || manualE == null) {
        this.message = "No has contestado '¿Cuentas con manual de identidad?' en la sección Empresa";
        this.status = "error";
      } else if (estudioE == undefined || estudioE == "" || estudioE == null) {
        this.message = "No has contestado '¿Cuentas con estudio de mercado?' en la sección Empresa.";
        this.status = "error";
      } else if (directaE == undefined || directaE == "" || directaE == null) {
        this.message = "El campo 'Competencia directa' en la sección Empresa no es valido.";
        this.status = "error";
      } else if (
        indirectaE == undefined ||
        indirectaE == "" ||
        indirectaE == null
      ) {
        this.message = "El campo 'Competencia indirecta' en la sección Empresa no es valido.";
        this.status = "error";
      } else if (linkE == undefined || linkE == "" || linkE == null) {
        this.message = "El campo 'link' en la sección Empresa no es valido.";
        this.status = "error";
      } else if (
        inversionE == undefined ||
        inversionE == "" ||
        inversionE == null
      ) {
        this.message = "El campo 'inversión' en la sección Empresa no es valido.";
        this.status = "error";
      } else if (
        referenciaUnoE == undefined ||
        referenciaUnoE == "" ||
        referenciaUnoE == null
      ) {
        this.message = "Menciona al menos una Referencia en la sección Empresa.";
        this.status = "error";
      } /*else if (
        referenciaDosE == undefined ||
        referenciaDosE == "" ||
        referenciaDosE == null
      ) {
        this.msg = "El campo medio no es valido.";
        this.status = "error";
      } else if (
        referenciaTresE == undefined ||
        referenciaTresE == "" ||
        referenciaTresE == null
      ) {
        this.msg = "El campo medio no es valido.";
        this.status = "error";
      }*/ else if (
        nombrePE == undefined ||
        nombrePE == "" ||
        nombrePE == null
      ) {
        this.message = "El campo 'nombre' en la sección Persona Encargada no es valido.";
        this.status = "error";
      } else if (
        telefonoPE == undefined ||
        telefonoPE == "" ||
        telefonoPE == null
      ) {
        this.message = "El campo 'teléfono' en la sección Persona Encargada no es valido.";
        this.status = "error";
      } else if (correoPE == undefined || correoPE == "" || correoPE == null) {
        this.message = "El campo 'correo' en la sección Persona Encargada no es valido.";
        this.status = "error";
      }
      
      else {
      let data = new FormData();
      data.append('nombreE', nombreE);
      data.append('antiguedadE', antiguedadE);
      data.append('proSerE', proSerE);
      data.append('ubicacionE', ubicacionE);
      data.append('telefonoE', telefonoE);
      data.append('segmentacionE', segmentacionE);
      data.append('misionE', misionE);
      data.append('visionE', visionE);
      data.append('valoresE', valoresE);
      data.append('manualE', manualE);
      data.append('estudioE', estudioE);
      data.append('archivo1', archivo1);
      data.append('nombreArch1', nombreArch1);
      data.append('archivo2', archivo2);
      data.append('nombreArch2', nombreArch2);
      data.append('directaE', directaE);
      data.append('indirectaE', indirectaE);
      data.append('linkE', linkE);
      data.append('inversionE', inversionE);
      data.append('referenciaUnoE', referenciaUnoE);
      data.append('referenciaDosE', referenciaDosE);
      data.append('referenciaTresE', referenciaTresE);
      data.append('nombrePE', nombrePE);
      data.append('telefonoPE', telefonoPE);
      data.append('correoPE', correoPE);
      data.append('_method', 'POST');
      console.log(data)
        let response = await this.$store.dispatch("admin/enviarBrief", data);
        console.log(response)
        if (response.status == "success") {
          console.log(data)
          this.status = "success";
          this.message = "Mensaje enviado con exito.";
          this.nombreE = "";
          (this.antiguedadE = ""),
            (this.proSerE = ""),
            (this.ubicacionE = ""),
            (this.telefonoE = ""),
            (this.segmentacionE = ""),
            (this.misionE = ""),
            (this.visionE = ""),
            (this.valoresE = ""),
            (this.manualE = ""),
            (this.estudioE = ""),
            (this.directaE = ""),
            (this.indirectaE = ""),
            (this.archivo1 = []),
            (this.nombreArch1 = ""),
            (this.archivo2 = []),
            (this.nombreArch2 = ""),
            (this.linkE = ""),
            (this.inversionE = ""),
            (this.referenciaUnoE = ""),
            (this.referenciaDosE = ""),
            (this.referenciaTresE = ""),
            (this.nombrePE = ""),
            (this.telefonoPE = ""),
            (this.correoPE = "");
          this.delStatus();
        } else {
          this.status = "error";
          this.message =
            "Lo sentimos ha ocurrido un error al intentar entregar tu mensaje.";
        }
      }
    },
  },
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Brief - Legrafica';
            }
        },
  }
};
</script>
<style>
.B1 {
  width: 100%;
  /* height: 61.71875VW; */
  background-color: #e4e4f9;
  padding-top: 16.875vw;
  padding-bottom: 9.115vw;
}
.redone{
  color: rgb(247, 99, 99)!important;
}
.HB2 {
  display: flex;
}
.HB2 a {
  align-self: center;
}
.HB2 p {
  color: #987ef3;
  font-size: 1.1458333333333333vw;
  align-self: center;
}
.HB2 img {
  width: 3.90625vw;
  height: auto;
  margin-left: 2.5vw;
}

.B1F2_B1 {
  margin-left: 8.59375vw;
}
.B1F2 {
  display: flex;
}
.B1T1 {
  color: #ef67c2;
  font-size: 7.813vw;
  line-height: 7.813vw;
  font-family: "Gramatika-Medium";
  margin: 0vw 0vw 4.7vw;
}
p.B1Text {
  width: 30vw;
  font-size: 0.8333333333333334vw;
  line-height: 1.4984374999999999vw;
  font-family: "IBM Plex Mono";
}
.B1F2_B2 {
  width: 100%;
}
.B1F2_B2 img {
  width: 65.708333vw;
  margin-top: -17vw;
  position: absolute;
  right: 0;
}

/**FORMS*/
.forms-cont {
  width: 100%;
  background-color: #e4e4f9;
}
.forms-cont form {
  display: flex;
  width: fit-content;
  margin: auto;
  padding-bottom: 11.719vw;
}
.inputs {
  display: flex;
  flex-direction: column;
}
.two-checks {
    display: flex;
    align-items: center;
    width: 11vw;
    height: 1vw;
    justify-content: space-between;
}
.checkbox-c {
  display: flex;
  align-items: center;
}
.check-c {
    display: flex;
    align-items: center;
}
.referenciasE {
  resize: none;
  background: transparent;
  border: none;
  border-bottom: 0.052vw solid black;
  width: 100%;
  padding: 0;
}
p.titulo-form {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 0.833vw;
  line-height: 246.8%;
  color: #ef67c2;
  margin: 0 0 2.135vw;
}
.three-inputs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 3.906vw;
}
.three-inputs .inputs input {
  width: 12.031vw;
  height: 1.51vw;
  background: transparent;
  border: none;
  border-bottom: 0.052vw solid black;
    font-size: 0.825vw;
}
.two-input {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 3.906vw;
}
.two-input.check-cont .inputs {
  width: 15.99vw;
}
.two-input .inputs input {
  width: 19.271vw;
  height: 1.51vw;
  background: transparent;
  border: none;
  border-bottom: 0.052vw solid black;
    font-size: 0.825vw;
}
.one-input {
  width: 100%;
  margin-bottom: 3.906vw;
}
.one-input .inputs input {
  width: 100%;
  height: 1.51vw;
  background: transparent;
  border: none;
  border-bottom: 0.052vw solid black;
    font-size: 0.825vw;
}
.inputs label,
.check-t {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
    font-size: 0.825vw;
  line-height: 246.8%;
  color: #000000;
  margin: 0 0 1.667vw;
}
.check-t.word {
  padding-left: 0.677vw;
  margin: 0;
  line-height: 0.625vw;
}
label.uploadManual {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 0.625vw;
  line-height: 246.8%;
  color: #ef67c2;
  cursor: pointer;
  margin: 0 2.4vw 0 1vw;
  pointer-events: all;
}
.two-checks .disabledAdd{
  color: #f0c0df;
  pointer-events: none;
}
label.uploadManual p {
  margin: 0;
}
.two-input .inputs .radio {
  width: 1vw;
  height: 1vw;
  margin: 0;
}
p.filename {
    margin: 1vw 0 0;
    font-size: .7vw;
    font-family: "IBM Plex Mono";
    color: #ed73c5;
    font-weight: bold;
}
.column-form:nth-child(1) {
  width: 41.042vw;
  padding-right: 5.938vw;
  border-right: 1px solid black;
}
.column-form:nth-child(2) {
  width: 20.677vw;
  padding-left: 5.938vw;
}
.submit-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.submit-cont.pc {
  display: flex;
}
.submit-cont.mo {
  display: none;
}
input.submitInfo {
  font-family: "Gramatika-Bold";
  width: 10vw;
  height: 2.031vw;
  font-size: 0.677vw;
  line-height: 152.3%;
  color: #000000;
  background: transparent;
  border: 0;
  padding-left: 2.904vw;
  background-image: url("../assets/send-btn.svg");
  background-size: 2.031vw;
  background-repeat: no-repeat;
  margin-bottom: 1.271vw;
  cursor: pointer;
}
p.confidencial {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 0.625vw;
  line-height: 246.8%;
  color: #000000;
  text-align: right;
  margin: 0;
  margin-top: 3.271vw;
}

@media (max-width: 768px) {
  .B1 {
    padding-top: 41.787vw;
    padding-bottom: 11.594vw;
    width: 100%;
  }
  p.B1T1 {
    font-size: 14.493vw;
    line-height: 14.493vw;
    margin: 0vw 0vw 6.039vw;
  }
  .B1F2_B1 {
    margin-left: 14.251vw;
  }
  p.titulo-form {
    font-size: 3.865vw;
    line-height: 246.8%;
    margin: 0 0 13.043vw;
  }
  .inputs label,
  .check-t {
    font-size: 2.899vw;
    margin: 0 0 3.667vw;
  }

  p.B1Text {
    width: 75vw;
    font-size: 2.174vw;
    line-height: 179.8%;
  }
  .B1F2_B2 img {
    display: none;
  }
  .forms-cont form {
    padding-bottom: 0;
    flex-direction: column;
    margin: 0;
    width: 100%;
  }
  .column-form:nth-child(1) {
    width: 100%;
    padding-right: 0;
    border-right: 0;
  }
  .column-form:nth-child(2) {
    width: 100%;
    padding-left: 0;
  }
  .three-inputs,
  .two-input {
    flex-direction: column;
    margin-bottom: 0;
  }
  .one-input {
    margin-bottom: 8.696vw;
  }
  .three-inputs .inputs input,
  .two-input .inputs input,
  .one-input .inputs input {
    width: 100%;
    height: 6.763vw;
    font-size: 2.899vw;
  }
  .three-inputs .inputs,
  .two-input .inputs {
    margin-bottom: 8.696vw;
  }
  .two-input.check-cont .inputs {
    width: 100%;
  }
  .two-input .inputs .radio {
    width: 4vw;
    height: 4vw;
    margin: 0;
  }
  p.filename {
    margin: 8vw 0 0;
    font-size: 2.7vw;
}
  .check-t.word {
    padding-left: 2.677vw;
    line-height: 3.625vw;
  }
  label.uploadManual {
    margin: 0 6.4vw 0 4vw;
  }
  .bloque-1,
  .bloque-2,
  .bloque-3 {
    width: 100%;
  }
  .bloque-1 {
    width: 100%;
  }
  .bloque-1 .formulario {
    margin: auto;
    padding: 14.976vw 0;
    width: 70.531vw;
  }
  .bloque-2 {
    width: 100%;
    background-color: white;
  }
  .bloque-2 .formulario {
    margin: auto;
    padding: 14.976vw 0;
    width: 70.531vw;
  }
  .bloque-3 {
    width: 100%;
    background-color: #f1f1f1;
  }
  .bloque-3 .formulario {
    margin: auto;
    padding: 14.976vw 0;
    width: 70.531vw;
  }
  .submit-cont.pc {
    display: none;
  }
  .submit-cont.mo {
    display: flex;
  }
  p.confidencial {
    font-size: 2.174vw;
    text-align: left;
    margin-top: 17.696vw;
  }
  input.submitInfo {
    font-size: 3.14vw;
    line-height: 152.3%;
    width: 42vw;
    height: 9.42vw;
    background-size: 9.42vw;
    padding-left: 12.904vw;
    margin-bottom: 3.565vw;
    margin-top: 8.696vw;
  }
  .two-checks {
    width: 44vw;
    height: 4vw;
  }
}
</style>